<template>
<div>
  <div class="content">
      <div class="title">
        按科室导出

      </div>
<!--      <div class="excel-btn">
        <van-button icon="down" plain  @click="downLoad(k)">下载</van-button>
      </div>-->
    <div class="item">
      <van-row gutter="20">
        <van-col span="16">全部</van-col>
        <van-col span="8" style="font-size: 17px;color: #ff8800;text-align: right">
          <van-button icon="down" plain  @click="downLoad()">下载</van-button>
        </van-col>
      </van-row>
    </div>
    <div class="item" v-for="(item,key,index) in officeJson" v-bind:key="'office' + index">
      <van-row gutter="20">
        <van-col span="16">{{item.name}}</van-col>
        <van-col span="8" style="font-size: 17px;color: #ff8800;text-align: right">
          <van-button icon="down" plain  @click="downLoad(key)">下载</van-button>
        </van-col>
<!--        <van-col span="6" style="font-size: 17px;font-weight: bold;color: #ff8800;">{{item.check}}</van-col>
        <van-col span="6" style="font-size: 17px;font-weight: bold;color: #5AD8A6;">{{item.rectify}}</van-col>-->
      </van-row>
    </div>

  </div>
</div>
</template>

<script>
import {OfficeJson} from "@/api/dict"
import {
  Notify,Button,List,Col, Row
} from 'vant';
export default {
  name: "office",
  components:{
    [Notify.name]: Notify,
    [Button.name]: Button,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col
  },
  data(){
    return{
      officeJson:{}
    }
  },
  props:{
    search:{
      type:Object,
      default: ()=>{
        return {
          startTime:"",
          endTime:"",
          type:'yqfk'
        }
      }
    },
  },
  watch: {
    /*监听主题变化*/
    search: {
      handler(n, o) {
        if(n){
          console.log(n)
          // this.getData();
        }
      },
      deep: true
    }
  },
  mounted(){
    this.officeJson = OfficeJson;
  },
  methods:{

    downLoad(k){
      let url = '/zuul/cultural/check/analyze/record/export?sortColumn=officeIds&startTime='+ this.search.startTime + "&endTime="+
          this.search.endTime + "&recordType="+
          this.search.type;
      if(k){
        url += "&officeIds="+ k;
      }
      console.log(url,"key")
      window.open(url,"blank")

    }
  }

}
</script>

<style scoped lang="less">

/deep/ .van-button--plain {
  background-color: #fff;
  border-radius: 5px;
  height: 36px;
  margin-bottom: 5px;
  color: #3eabe1;
}

.content{
  position: relative;
}
.item{
  border-bottom: 1px solid #ebedf0;
  line-height: 46px;
  color: #666;
}
</style>
