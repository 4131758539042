<template>
<div>
  <div class="content" v-for="(s,k) in streetJson" v-bind:key="k">
      <div class="title"> {{ s }}
        <div style="position: absolute;right: 10px;top: 3px;font-weight: normal;font-size: 13px;">
          <span class="legend"></span>检查
          <span class="legend" style="background: #F4664A;"></span>需整改
        </div>
      </div>
      <div class="excel-btn">
        <van-button icon="down" plain  @click="downLoad(k)">下载</van-button>
      </div>
      <div class="total-label">总数&nbsp;{{streetJsonNum[k]}}</div>
      <div class="chart" :id="'chart'+k">

      </div>

  </div>
</div>
</template>

<script>
import * as echarts from 'echarts';
import {
  Notify,Button
} from 'vant';
export default {
  name: "street",
  components:{
    [Notify.name]: Notify,
    [Button.name]: Button
  },
  data(){
    return{
      streetJson:{
        "all":"全部",
        "3201050010":"莫愁湖街道",
        "3201050020":"沙洲街道",
        "3201050030":"双闸街道",
        "3201050040":"江心洲街道",
        "3201050060":"兴隆街道",
        "3201050070":"南苑街道",
      },
      streetJsonNum:{
        all:0,
        "3201050010":0,
        "3201050020":0,
        "3201050030":0,
        "3201050040":0,
        "3201050060":0,
        "3201050070":0,
      }
    }
  },
  props:{
    search:{
      type:Object,
      default: ()=>{
        return {
          startTime:"",
          endTime:"",
          type:'yqfk'
        }
      }
    },
  },
  watch: {
    /*监听主题变化*/
    search: {
      handler(n, o) {
        if(n){
          console.log(n)
          this.getData();
        }
      },
      deep: true
    }
  },
  mounted(){
    this.getData();
  },
  methods:{
    getData(){
      this.$http({
        url: "/zuul/cultural/check/analyze/street",
        method: "get",
        params:{
          startTime: this.search.startTime,
          endTime: this.search.endTime,
          recordType:this.search.type
        }
      }).then(res=>{
        console.log(res.data.data,"street");
        if(res.data.success){
          let checked = res.data.data["检查次数"];
          let rectifed = res.data.data["整改次数"];
          let streetArr = {};
      /*    [{ value: 1048, name: 'Search Engine' },
          { value: 735, name: 'Direct' }],*/
          for(let k in this.streetJson){
            this.getAll(k);
              let data = [
                {name:'检查',value:checked[k]?checked[k]:0,color:"red"},
                {name:'需整改',value:rectifed[k]?rectifed[k]:0,color:"green"}
              ]
            if(k!='all'){
              this.makePie(k,data)
            }

          }
          // console.log(streetArr);

        }

      })
    },
    makePie(selector,chartData){
      var chartDom = document.getElementById('chart' + selector);
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        color:["#FAAD14","#F4664A"],
        tooltip: {
          trigger: 'item'
        },
        grid: {
          right: '-30px',
          top:"-10px",
          bottom: '0px',
          containLabel: true
        },
        legend: {
          orient: 'vertical',
          top: '45%',
          right: '0',
          padding:[0,0,0,-100],
          formatter: function (name,value) {
            let data = option.series[0].data;
            let tarV = 0;
            data.map(item=>{
              if(item.name==name){
                tarV = item.value;
              }
            })
            return   name + "  " + tarV ;
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '60%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: chartData
          }
        ]
      };

      option && myChart.setOption(option);
    },
    getAll(street){
      this.$http({
        url: "/zuul/cultural/check/analyze/infoType",
        method: "get",
        params:{
          startTime: this.search.startTime,
          endTime: this.search.endTime,
          street:street=='all'?"":street,
          recordType:this.search.type
        }
      }).then(res=>{
        if(res.data.success){
          if(res.data.data["总数"]){
            console.log(res.data.data,"streeeAll")
             this.streetJsonNum[street] =Object.values(res.data.data["总数"]).reduce((n,m)=>n+m);
          }
          if(street=='all'){
            let c = 0,r=0;
            if(res.data.data["整改数量"]){
              r = Object.values(res.data.data["整改数量"]).reduce((n,m)=>n+m);
            }
            if(res.data.data["检查数量"]){
              c = Object.values(res.data.data["检查数量"]).reduce((n,m)=>n+m);
            }
            let data = [
              {name:'检查',value:c,color:"red"},
              {name:'需整改',value:r,color:"green"}
            ]
            this.makePie(street,data);
          }
        }
      })
    },
    downLoad(k){
      let url = '/zuul/cultural/check/analyze/record/export?sortColumn=street&startTime='+ this.search.startTime + "&endTime="+
          this.search.endTime + "&recordType="+
          this.search.type ;
      if(k!='all'){
        url += "&street="+ k;
      }
      window.open(url,"blank")

    }
  }

}
</script>

<style scoped lang="less">
/deep/ .van-button--plain {
  background-color: #fff;
  border-radius: 5px;
  height: 36px;
}
.content{
  position: relative;
}
.legend{
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #FAAD14;
  margin: 0px 5px 0px 15px;
}
.chart{
  width: calc(100% + 80px);
  position: relative;
  left: -80px;
}
.excel-btn{
  position: absolute;
  right: 10px;
  top: 50px;
  z-index: 99;
}
.total-label{
  position: absolute;
  right: 10px;
  top: 40%;
  font-size: 15px;
  color: #444;
  font-weight: 700;
}
</style>
