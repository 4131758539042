<template>
<div>
  <div class="content">
    <div class="title"> 检查人</div>
    <div>
      <van-row gutter="20" style="line-height: 36px;color: #a9adb1;font-weight: 600;">
        <van-col span="12">检查人</van-col>
        <van-col span="6" style="text-align: right;">检查数量</van-col>
        <van-col span="6" style="text-align: right;">整改数量</van-col>
      </van-row>
        <div class="item" v-for="(item,k) in tableJson" v-bind:key="k">
          <van-row gutter="20">
            <van-col span="12">{{(user[k]&&user[k].name)?user[k].name:k}}</van-col>
            <van-col span="6" style="font-size: 17px;font-weight: bold;color: #ff8800;">{{item.check}}</van-col>
            <van-col span="6" style="font-size: 17px;font-weight: bold;color: #5AD8A6;">{{item.rectify}}</van-col>
          </van-row>
        </div>
    </div>
  </div>
</div>
</template>

<script>
import {List,Col, Row} from 'vant';
export default {
  name: "person",
  components:{
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col
  },

  data(){
    return{
      user:{
      },
      officeJson:{},
      tableJson:{}
    }
  },
  props:{
    search:{
      type:Object,
      default: ()=>{
        return {
          startTime:"",
          endTime:"",
          type:'yqfk'
        }
      }
    },
  },
  watch: {
    /*监听主题变化*/
    search: {
      handler(n, o) {
        if(n){
          console.log(n)
          this.getData();
        }
      },
      deep: true
    }
  },
  mounted(){
    Promise.all([ this.getUser()]).then(res=>{
      this.getData();
    })
  },
  methods:{
    getData(){
      this.$http({
        url: "/zuul/cultural/check/analyze/person",
        method: "get",
        params:{
          startTime: this.search.startTime,
          endTime: this.search.endTime,
          recordType:this.search.type
        }
      }).then(res=>{
        console.log(res,"resres")
        if(res.data.success){
          let check= res.data.data["检查次数"];
          let rectify= res.data.data["整改次数"];
          this.tableJson = {};
          for(let k in check){
            if(!this.tableJson[k]){
              this.$set(this.tableJson,k, {
                check:0,
                rectify:0
              })
            }
            this.tableJson[k].check = check[k]
          }
          for(let j in rectify){
            if(!this.tableJson[j]){
              this.$set(this.tableJson,j, {
                check:0,
                rectify:0
              })
            }
            this.tableJson[j].rectify = rectify[j]
          }
          console.log(this.tableJson,"tableJson")
        }
      })
    },
    getOffice(){
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/upm/office/children/320105",
          method: "get",
          headers: {'OAuth': "AnonymousLogin"},
        }).then(res=>{
          if(res.data.success){
            this.officeList = res.data.data;
            res.data.data.map(item=>{
              this.officeJson[item.id] = item;
            })
          }
          resolve(true)
        })
      })

    },
    getUser(){
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/user/raw",
          method: "get",
        }).then(res=>{
          if(res.data.success){
            console.log(res.data.data,"useuserr")
            res.data.data.map(item=>{
              this.user[item.openid] = item;
            })
          }
          resolve(true)
        })
      })

    },
  }
}
</script>
<style scoped>
/deep/ .van-col{
  word-break: break-all;
}
/deep/ .van-col--6{
  text-align: right;
}

</style>
<style scoped lang="less">
.content{
  height: auto;
}
.item{
  border-bottom: 1px solid #ebedf0;
  line-height: 26px;
  color: #666;
}
</style>
