<template>
  <div class="statistics">
      <div >
        <img :src="require('./img/bg.png')" alt="" width="100%" style="margin-top: -28px;">
      </div>
      <div class="total">
        <div class="office">{{user.name}} &nbsp;<span>{{user.officeName}}</span>
          <span style="position:absolute;right: 20px;top: 5px;font-weight: normal;">{{formatDate(new Date())}}</span>
        </div>
        <van-row gutter="16" type="flex" justify="space-around">
          <van-col span="8">
            <div class="item" @click="goToMap('map1')">
                <div class="icon">
                  <van-badge :content="total.unchecked.num" >
                    <van-image
                        width="32"
                        height="32"
                        fit="contain"
                        :src="total.unchecked.img"
                    />
                  </van-badge>
                </div>
                <div class="des">待检查</div>
            </div>
          </van-col>
          <van-col span="8">
            <div class="item"  @click="goToMap('rectify')">
              <div class="icon" style="background: #ff8800">
                <van-badge :content="total.checked.num">
                  <van-image
                      width="32"
                      height="32"
                      fit="contain"
                      :src="total.checked.img"
                  />
                </van-badge>

              </div>
              <div class="des">待整改</div>
            </div>
          </van-col>
          <van-col span="8">
            <div class="item"  @click="goToMap('rectify')">
              <div class="icon" style="background: #00C9A7">
                <van-badge :content="total.unconfirm.num">
                  <van-image
                      width="32"
                      height="32"
                      fit="contain"
                      :src="total.unconfirm.img"
                  />
                </van-badge>
              </div>
              <div class="des">待确认</div>
            </div>
          </van-col>
        </van-row>
      </div>

      <div class="analyse">

        <div class="btn type" style="position:relative;top: -30px;">
          <van-row gutter="0">
<!--            <van-col span="8">
              <div class="type-info-tab tab1 active" @click="changeType(1)">
                <span>疫情防控</span>
              </div>
            </van-col>-->
            <van-col span="12">
              <div class="type-info-tab tab2 active" @click="changeType(1)">
                <span>行业检查</span>
              </div>
            </van-col>
            <van-col span="12">
              <div class="type-info-tab tab2" @click="changeType(2)">
                <span>专项检查</span>
              </div>
            </van-col>
<!--            <van-col span="8">
              <div class="type-info-tab tab2" @click="changeType(3)">
                <span>文明城市</span>
              </div>
            </van-col>-->

          </van-row>
        </div>

        <van-tabs type="card"  v-model="timeTab"  @click="timeChange">
          <van-tab title="当日" name="0"></van-tab>
          <van-tab title="本周" name="1"></van-tab>
          <van-tab title="本月" name="2"></van-tab>
          <van-tab title="自定义" name="3"></van-tab>
        </van-tabs>
        <div class="basic">
          <van-row gutter="16" type="flex" justify="space-around">
            <van-col span="6" class="text-center num">
             {{all.total}}
            </van-col>
            <van-col span="6" class="text-center num">
              {{all.checkInfo}}
            </van-col>
            <van-col span="6" class="text-center num">
              {{all.checked}}
            </van-col>
            <van-col span="6" class="text-center num">
              {{all.person}}
            </van-col>
            <van-col span="6" class="text-center des">
              企业总数
            </van-col>
            <van-col span="6" class="text-center des">
              检查企业
            </van-col>
            <van-col span="6" class="text-center des">
              检查次数
            </van-col>
            <van-col span="6" class="text-center des">
              检查人数
            </van-col>
          </van-row>
        </div>
        <div class="btn">
          <van-row gutter="16">
            <van-col span="6">
              <div class="type-tab tab1 active" @click="changeContent(1)">
                <span>行业</span>
              </div>
            </van-col>
            <van-col span="6">
              <div class="type-tab tab2" @click="changeContent(2)">
                <span>区域</span>
              </div>
            </van-col>
            <van-col span="6">
              <div class="type-tab tab2" @click="changeContent(3)">
                <span>科室</span>
              </div>
            </van-col>
            <van-col span="6">
              <div class="type-tab tab2"  @click="changeContent(4)">
                <span>人员</span>
              </div>
            </van-col>
          </van-row>
        </div>
        <div class="section section1" v-if="contentName==1">
          <view-all :search="searhCondition" :cultureDist="cultureDist" :itemJson="itemJson" :infoType="infoType"></view-all>
        </div>
        <div class="section section2" v-else-if="contentName==2">
          <street :search="searhCondition"></street>
        </div>
        <div class="section section3" v-else-if="contentName==3">
          <office :search="searhCondition"></office>
        </div>
        <div class="section section4" v-else>
          <person-s :search="searhCondition"></person-s>
        </div>
      </div>
    <van-calendar v-model="calendarShow" type="range" @confirm="onConfirm" :min-date="minDate" :max-range="365" :style="{ height: '500px' }"  />
  </div>
</template>

<script>
import personS from "./person";
import street from "./street";
import viewAll from "./viewAll";
import office from "./office";
import {RenderCheckDict,RenderCulturalDict} from "@/api/dict"
import {formatDate,getStartEndByMonth,getWeekEndDate,getRecentMonth} from "@/api/time"
import {
  Button, Col, Row,Popup,Image,Icon,Search,Badge,NoticeBar,Tab, Tabs,Calendar
} from 'vant';
export default {
  name: "index",
  components:{personS,street,viewAll,office,
    [Calendar.name]: Calendar,
    [Search.name]: Search,
    [Button.name]: Button,
    [Popup.name]: Popup ,
    [Icon.name]: Icon ,
    [Image.name]: Image ,
    [Badge.name]: Badge ,
    [NoticeBar.name]: NoticeBar ,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Col.name]: Col,
    [Row.name]: Row
  },
  data(){
    return {
      total:{
        unchecked:{
          img:require('./img/weichakan.svg'),
          num:45
        },
        checked:{
          img:require('./img/zhenggaicuoshi.svg'),
          num:23
        },
        unconfirm:{
          img:require('./img/gongdanqueren.svg'),
          num:12
        }
      },
      user:{
        name:"",
        openid:"",
        officeId:"",
        officeName:""
      },
      officeJson:{},
      all:{
        total:693,
        checked:540,
        person:24,
        checkInfo:34
      },
      infoType:{

      },
      calendarShow:false,
      minDate:new Date(2021,9,1),
      searhCondition:{
        startTime:"",
        endTime:"",
        type:""
      },
      contentName:1,
      itemJson:{},
      cultureDist:{},
      aqscTables:"",
      aqsc:{

      },
      zxjcTables:"",
      zxjc:{

      },
      wmcs:{

      },
      checkTable:{},
      timeTab:1
    }
  },
  methods:{
    goToMap(title){
      this.$router.push({
        /*path: '/'  + title,
        query: {
          status: 2
        }*/
        name: title,
        params: {
          status: 2
        }
      })
    },
    getOffice(){
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/upm/office/children/320105",
          method: "get",
          headers: {'OAuth': "AnonymousLogin"},
        }).then(res=>{
          if(res.data.success){
            res.data.data.map(item=>{
              this.officeJson[item.id] = item;
            })
          }
          resolve(true)
        })
      })

    },
    changeType(type){
      console.log(type,"type")
      switch (type){
       /* case 1:
          this.searhCondition.type = 'yqfk,yqfk1,yqfk2';
          break;*/
        case 1:
          this.searhCondition.type = this.aqscTables;
          break;
        case 2:
          this.searhCondition.type = this.zxjcTables;
          break;
        default:
          // this.searhCondition.type = 'yqfk,yqfk1,yqfk2';
          this.searhCondition.type = this.aqscTables;
          break;
      }
      this.changeContent(1);
      this.timeTab = 1;
      this.timeChange(2);
      let btn = document.getElementsByClassName("type-info-tab");
      for(let i=0;i<btn.length;i++){
        btn[i].classList.remove("active");
        if(i+1 ==type){
          btn[i].classList.add("active");
        }
      }
    },
    // 获取所有检查表
    getCheckTables(){
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/check/table/raw",
          method: 'get'
        }).then(result => {
          if(result.data.success){
            console.log(result.data.data);
            this.aqsc = {};
            result.data.data.map(item=>{
              if(item.typeList){
                item.typeList.map(i=>{
                  if(!this.itemJson[i.id]){
                    this.itemJson[i.id] = i.name;
                  }
                })
              }
              if(!this.checkTable[item.id]){
                this.checkTable[item.id] = item.typeList;
              }
              if(item.type=='aqsc'){
                if(this.aqscTables){
                  this.aqscTables += ","+item.id;
                }else{
                  this.aqscTables = item.id;
                }
                if(!this.aqsc[item.infoType]){
                  this.aqsc[item.infoType] = item.typeList;
                }
              }else if(item.type=='wmcs'){
                if(!this.wmcs[item.infoType]){
                  this.wmcs[item.infoType] = item.typeList;
                }
              }else if(item.type=='zxjc'){
                if(this.zxjcTables){
                  this.zxjcTables += ","+item.id;
                }else{
                  this.zxjcTables = item.id;
                }
                if(!this.zxjc[item.id]){
                  this.zxjc[item.id] = item;
                }
              }
            })
            resolve(true);
          }

        })
      })
    },
    changeContent(type){
      this.contentName = type;
      let btn = document.getElementsByClassName("type-tab");
      for(let i=0;i<btn.length;i++){
        btn[i].classList.remove("active");
        if(i+1 ==type){
          btn[i].classList.add("active");
        }
      }
    },
    getDict(){
      return new Promise(resolve => {
        RenderCheckDict((data) => {
          if(data.typeList){
            data.typeList.map(item=>{
              this.itemJson[item.id] = item.name;
            })
          }
          resolve(this.itemJson)
        })
      })
    },
    getCultureDict(){
      return new Promise(resolve => {
        RenderCulturalDict((data) => {
          data.map(item=>{
            // this.cultureDist[item.id] = item.name;
            this.$set(this.cultureDist,item.id,item.name);
          })
          resolve(this.cultureDist)
        })
      })
    },
    getData(){
      this.getAllNum();
      this.getTypeNum();
      this.getPerNum();
    },
    timeChange(name, title){
      if(name==1){
        this.searhCondition.startTime = getWeekEndDate()[0];
        this.searhCondition.endTime = getWeekEndDate()[1];
        this.getData();
      }else if(name==2){
        this.searhCondition.startTime = getStartEndByMonth()[0];
        this.searhCondition.endTime = getStartEndByMonth()[1];
        this.getData();
      }else if(name==0){
        this.searhCondition.startTime = formatDate(new Date()) + " 00:00:00";
        this.searhCondition.endTime = formatDate(new Date())+ "  23:59:59";
        this.getData();
      }else if(name==3){
        this.calendarShow = true;
      }
      console.log(this.searhCondition.startTime,this.searhCondition.endTime)
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      let [start, end] = date;
      this.calendarShow = false;
      this.searhCondition.startTime =this.formatDate(start);
      this.searhCondition.endTime =this.formatDate(end);
      this.getData();
    },
    getAllNum(){
      this.$http({
        url: "/zuul/cultural/check/analyze/count",
        method: "get",
        params:{
          startTime: this.searhCondition.startTime,
          endTime: this.searhCondition.endTime,
          recordType:this.searhCondition.type
        }
      }).then(res=>{
        if(res.data.success){
          console.log(res.data.data,"klsks")
          this.all.checkInfo = res.data.data["检查企业总数"]?res.data.data["检查企业总数"]:"--";
          this.all.checked = res.data.data["检查次数"]?res.data.data["检查次数"]:"--";
          this.all.person = res.data.data["检查人"]?res.data.data["检查人"]:"--";
        }
      })
    },
    getTypeNum(){
      this.$http({
        url: "/zuul/cultural/check/analyze/infoType",
        method: "get",
        params:{
          startTime: this.searhCondition.startTime,
          endTime: this.searhCondition.endTime,
          recordType:this.searhCondition.type
        }
      }).then(res=>{
        if(res.data.success){
          if(res.data.data["总数"]){
            this.all.total =Object.values(res.data.data["总数"]).reduce((n,m)=>n+m);
          }
          this.infoType = {
            check:res.data.data["检查数量"],
            rectify:res.data.data["整改数量"],
            total:res.data.data["总数"]
          }
        }
      })
    },
    getPerNum(){
      this.$http({
        url: "/zuul/cultural/user/todo/" + localStorage.getItem("wljptuser"),
        method: "get",
        params:{
          startTime: this.searhCondition.startTime,
          endTime: this.searhCondition.endTime,
          recordType:this.searhCondition.type
        }
      }).then(res=>{
          if(res.data.success){
            console.log(res.data.data,"person");
            this.total.unchecked.num = res.data.data["未检查"];
            this.total.checked.num = res.data.data["待整改"];
            this.total.unconfirm.num = res.data.data["待确认"];
          }
      })
    }
  },
  mounted(){

    Promise.all([ this.getOffice(),this.getDict(),this.getCultureDict(),this.getCheckTables()]).then(res=>{
      if(localStorage.getItem('officeIds')){
        this.user.officeId = localStorage.getItem('officeIds');
        this.user.officeName = this.officeJson[this.user.officeId]?this.officeJson[this.user.officeId].name:""
      }
      this.searhCondition.type = this.aqscTables;
        this.timeChange(0);
        this.getData();
    })
    this.user.name = localStorage.getItem("wljptuserName");
    this.user.openid = localStorage.getItem("wljptuser");

    console.log(localStorage,this.user,"user")
  },
  watch:{

  }
}
</script>
<style scoped>
.statistics .van-badge__wrapper{
  padding-top: 15px !important;
}
.statistics /deep/.van-badge{
  min-width: 32px !important;
  height: 24px !important;
  font-size: 15px !important;
  line-height: 1.4;
}
.statistics /deep/.van-tabs__nav--card .van-tab{
  color: #ff8800;
  border-right: 1px solid #ff8800;
}
.statistics /deep/.van-tabs__nav--card{
  border: 1px solid #ff8800;
  border-right: none;
}
.statistics /deep/.van-tabs__nav--card .van-tab.van-tab--active{
  color:white;
  background: #ff8800;
}
.statistics /deep/.van-tabs__nav--card{
  margin: 0px;
}
</style>
<style lang="less">
    .text-center{
      text-align: center;
    }
    .statistics{
      width: calc(100vw);
      min-height: calc(100vh);

      .total{

        position: relative;
        top: -30px;
        width: calc(100% - 48px);
        height: 130px;
        //background-color: #e9f3fd;
        background-color: #fffaf0;
        border-radius: 25px;
        padding: 24px;
        /*border-top-left-radius: 30px;
        border-top-right-radius: 30px;*/
        .item{
          width: 100%;
          height: 95px;
          //background:#4885F7 ;
          text-align: center;
          padding-top: 16px;
          border-radius: 4px;

          .icon{
            display: inline-block;
            background: #3eabe1;
            width: 60px;
            height: 60px;
            border-radius: 60px;
            text-align: center;
          }
          .des{
            line-height: 40px;
            color: #555;
            font-size:13px;
            font-weight: bold;
          }
        }

        .office{
          position: relative;
          top: -14px;
          color: #14006B;
          font-weight: 900;
          font-size: 16px;

          span {
            color: #333;
            font-size:13px;
          }
        }
      }

      .analyse{
        position: relative;
        top: -18px;
        //background: #F2EAFC;
        background: #fffaf0;
        border-radius: 25px;
        padding: 16px;

        .btn{
          width: calc(100% - 0px);
          //background:#F2EAFC;
          background:#fcf0dc;
          height: 40px;
          line-height: 40px;
          padding: 8px 0px;
          border-radius: 25px;
          font-size:18px;
          color: #14006B;
          font-weight: bold;
          letter-spacing: 2px;
          box-shadow: 2px 5px 8px #fadf84;
          div{
            text-align: center;
          }
          .type-tab,.type-info-tab{
            text-align: center;
            span{
              padding: 0px 8px;
            }
          }
          .active{
            color: white;
            span{
              display: inline-block;
              border-radius:16px;
              background-image: linear-gradient(to right top, #ff976a, #ee0a24)
              //background-image: linear-gradient(to right top, #433eec, #5f4aed, #7556ee, #8763f0, #9770f1);
              }
          }
        }
      }

      .basic{
        padding: 20px 0px;

        .num{
          font-size: 34px;
          font-weight: bold;
          //color: #3D45E9;
          color: #ff8800;
        }
        .des{
          font-size: 15px;
          color: #404372;
        }

      }

      .section .content{
        margin-top: 16px;

        padding: 12px;
        border-radius: 16px;
        background: white;

        .title{
          color: #14006B;
          font-size: 18px;
          font-weight: bold;
          position: relative;
          span{
            display: inline-block;
          }
        }
        .title::after{
          content: "";
          height: 1px;
          display: inline-block;
          width: 100%;
          position: relative;
          top: -12px;
          background-image: linear-gradient(to right top, #ffffff, #ffffff,#ffffff, #e3dcff, #c8b8fc, #af95f8, #9770f1);
        }

        .chart{
          height:240px;
        }
      }
    }

</style>
