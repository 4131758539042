<template>
  <div>
    <div class="content" style="display: none;">
      <div class="title">
        每日检查
        <div style="position: absolute;right: 10px;top: 3px;font-weight: normal;font-size: 13px;">
          <span class="legend" style="background: #FAAD14;"></span>检查
          <span class="legend"></span>需整改
        </div>
      </div>
      <div class="chart" id="checkLine">

      </div>
    </div>
    <div class="content">
      <div class="title">
        企业类型
        <div style="position: absolute;right: 10px;top: 3px;font-weight: normal;font-size: 13px;">
          <span class="legend" style="background: #30BF78;"></span>总数
          <span class="legend" style="background: #FAAD14;"></span>检查
          <span class="legend"></span>需整改
        </div>
      </div>
      <div class="chart" id="checkType">

      </div>
      <div class="title">
          按类型导出
      </div>
      <div>
        <van-row gutter="16">
          <van-col span="6">
            <van-button plain type="primary" @click="downLoad()">全部</van-button>
          </van-col>
          <van-col span="6" v-for="(obj,key,index) in cultureType" v-bind:key="'type' + index">
            <van-button  v-if="key!='999'" plain type="info" @click="downLoad(key)">{{obj}}</van-button>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="content">
      <div class="title">问题类型分析</div>
      <div class="chart" id="checkItem">
        <van-button plain type="primary">朴素按钮</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {getBetweenDays} from "@/api/time"
import * as echarts from 'echarts';
import {
  Button, Col, Row,Popup,Image,Icon,Badge,NoticeBar,Tab, Tabs
} from 'vant';
import personS from "@/view/statistics/person";
import street from "@/view/statistics/street";
export default {
  name: "charts",
  components:{
    [Button.name]: Button,
    [Popup.name]: Popup ,
    [Icon.name]: Icon ,
    [Image.name]: Image ,
    [Badge.name]: Badge ,
    [NoticeBar.name]: NoticeBar ,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props:{
    search:{
      type:Object,
      default: ()=>{
        return {
          startTime:"",
          endTime:"",
          type:'yqfk'
        }
      }
    },
    itemJson:{
      type:Object,
      default: ()=>{
        return {}
      }
    },
    cultureDist:{
      type:Object,
      default: ()=>{
        return {}
      }
    },
    infoType:{
      type:Object,
      default: ()=>{
        return {
          "check": {},
          "rectify":{},
          "total":{}
        }
      }
    },
  },
  watch: {
    /*监听主题变化*/
    search: {
      handler(n, o) {
        if(n){
          console.log(this.cultureDist,"culDict")
          this.timeList = getBetweenDays(n.startTime,n.endTime);
         /* this.makeLine('checkLine',"line");
          this.makeLine("checkType","bar")*/
          this.getPerson();
          this.getItems();
          this.parseInfoType();
        }

      },
      deep: true
    },
    infoType: {
      handler(n, o) {

        if(n){
          this.parseInfoType();
        }

      },
      deep: true
    },
    cultureDist: {
      handler(n, o) {
        console.log(n,"changeCulturaldict")
        if(n){
          this.cultureType = n;
        }

      },
      deep: true,
      immediate:true
    }
  },
  data(){
    return {
      lineChart:'',
      lineData:[],
      columnChart:'',
      columnData:[],
      pieChart:"",
      pieData:[],
      timeList:[],
      dayRectifyData:{},
      cultureType:{}
    }
  },
  mounted(){

    this.parseInfoType();
    this.getPerson();
    this.getItems();
    if(this.search.startTime){
      this.timeList = getBetweenDays(this.search.startTime,this.search.endTime);
    }

    this.makePie();
  },
  methods:{
      getItems(){
        this.$http({
          url: "/zuul/cultural/check/analyze/problemType",
          method: "get",
          params:{
            startTime: this.search.startTime,
            endTime: this.search.endTime,
            recordType:this.search.type
          }
        }).then(res=>{
          if(res.data.success){
              let chartData = [];

             for(let k in res.data.data){
               chartData.push({
                 name:this.itemJson[k]?this.itemJson[k]:"未知",
                 value: res.data.data[k]
               })
             }
             chartData.sort(this.sortData("value"));
             let newData = chartData.splice(0,chartData.length>10?10:chartData.length);
             this.makePie(newData);
          }
        })
      },
      sortData(p){
        return function(m,n){
          var a = m[p];
          var b = n[p];
          return  b-a; //升序
        }
      },
      getDayRectifyData(){
        return new Promise(resolve => {
          this.$http({
            url: "/zuul/cultural/check/analyze/daily",
            method: "get",
            params:{
              startTime: this.search.startTime,
              endTime: this.search.endTime,
              hasProblem:true,
              recordType:this.search.type
            }
          }).then(res=>{
            if(res.data.success){
              let timeData = {};
              for(let k in res.data.data){
                let key = k;
                if(key.length==7){
                  key =  key.slice(0,6) +'0' +key.slice(6)
                }
                timeData[key] = res.data.data[k]
              }
              let data = this.timeList.map(t=>{
                return timeData[t]?timeData[t]:0;
              })
              this.dayRectifyData = {
                  name:"需整改",
                  type: "line",
                  smooth:true,
                  itemStyle:{
                    // color:"#5B57EF"
                    color:"#F4664A "
                  },
                  data:data
                };
            }
            resolve(this.dayRectifyData)
          })
        })

      },
      getPerson(){
        Promise.all([ this.getDayRectifyData()]).then(res=>{
          this.getPerson1();
        })
      },
      getPerson1(){

        this.$http({
          url: "/zuul/cultural/check/analyze/daily",
          method: "get",
          params:{
            startTime: this.search.startTime,
            endTime: this.search.endTime,
            recordType:this.search.type
          }
        }).then(res=>{
          if(res.data.success){
            let timeData = {};
            for(let k in res.data.data){
              let key = k;
              if(key.length==7){
                key =  key.slice(0,6) +'0' +key.slice(6)
              }
              timeData[key] = res.data.data[k]
            }
            let data = this.timeList.map(t=>{
              return timeData[t]?timeData[t]:0;
            })
            let chartD = [
              {
                name:"检查",
                type: "bar",
                smooth:true,
                itemStyle:{
                  // color:"#5B57EF"
                  color:"#FAAD14"
                },
                data:data
              }
            ]
            chartD.push(this.dayRectifyData);
            this.makeLine('checkLine',"bar",chartD);

          }
        })
      },
      makeLine(selector,type,data){
        let chartDom = document.getElementById(selector);
        this.lineChart = echarts.init(chartDom);
        let option = {
          grid: {
            left: '10px',
            right: '10px',
            top:"-10px",
            bottom: '0px',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          toolbox: {
          },
          legend: {
            data: ['检查', '整改'],
            right:"10px",
            top:"-50px"
          },
          xAxis: [
            {
              type: 'category',
              data: this.timeList,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {rotate: 50},
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '次',
              minInterval:1,
              min:0,
              axisLabel: {
                formatter: '{value}'
              }
            }
          ],
          series: data
        };

        option && this.lineChart.setOption(option);
      },
      makeColumn(selector,type,axis,data){
        let chartDom = document.getElementById(selector);
        this.lineChart = echarts.init(chartDom);
        let option = {
          grid: {
            left: '10px',
            right: '10px',
            top:"-10px",
            bottom: '0px',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          toolbox: {
          },
          legend: {
            data: ['总数', '检查', '需整改'],
            right:"10px",
            top:"-50px"
          },
          xAxis: [
            {
              type: 'category',
              data: axis,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {rotate: 50},
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '次',
              minInterval:1,
              min:0,
              axisLabel: {
                formatter: '{value}'
              }
            }
          ],
          series: data
        };

        option && this.lineChart.setOption(option);
      },
      parseInfoType(){
        console.log(this.infoType,"infoType")
        let axis = Object.values(this.cultureDist);
        let axisId = Object.keys(this.cultureDist);
        let chartData = [];
        let name = {
          "check": {
            name:"检查",
            color:"#FAAD14"
          },
          "rectify":{
            name:"需整改",
            color:"#F4664A"
          },
          "total":{
            name:"总数",
            color:"#30BF78"
          }

        };
        for(let k in this.infoType){
          let data = [];

          if(this.infoType[k]){
            data = axisId.map(id=>{
              return this.infoType[k][id]?this.infoType[k][id]:0
            })
          }else{
            data= axisId.map(id=>{
              return 0
            })
          }
          chartData.push({
            name: name[k].name,
            type: "bar",
            smooth:true,
            itemStyle:{
              // color:k=='check'?"#5B57EF":"#A07CF2"
              color:name[k].color
            },
            data:data
          })
        }
        this.makeColumn("checkType","bar",axis,chartData);

      },
      makePie(chartData){
        let chartDom = document.getElementById('checkItem');
        let myChart = echarts.init(chartDom);
        let option;

        let data = chartData?chartData:[
            { name: '疫情防控提示告示宣传海报', value: 6},
            { name: '防疫物资储备', value: 3 },
            { name: '临时隔离间（点）是否规范', value: 8 },
            { name: '一米线设置', value: 5 },
            { name: '员工佩戴口罩', value: 4 },
            { name: '顾客登记、测温', value: 3 }
          ];
        option = {
          color:["#5B8FF9 ","#5AD8A6 ","#5D7092","#F6BD16  ","#E86452 ","#6DC8EC ","#945FB9","#FF9845","#1E9493","#FF99C3" ],
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          series: {
            name:"检查项",
            type: 'pie',
            radius: [40, 70],
            top: '0',
            height: '100%',
            left: 'center',
            width: window.innerWidth - 40,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            label: {
              alignTo: 'edge',
              formatter: '{name|{b}}\n{time|{c} 次}',
              minMargin: 2,
              edgeDistance: 25,
              lineHeight: 15,
              rich: {
                time: {
                  fontSize: 10,
                  color: '#999'
                }
              }
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 80
            },
            labelLayout: function (params) {
              const isLeft = params.labelRect.x < myChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                  ? params.labelRect.x
                  : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points
              };
            },
            data: data
          }
         }

        option && myChart.setOption(option);
      },
    downLoad(k){
      let url = '/zuul/cultural/check/analyze/record/export?sortColumn=infoType&startTime='+ this.search.startTime + "&endTime="+
          this.search.endTime  +  "&recordType="+
      this.search.type;
      if(k){
        url += "&type="+ k
      }
      window.open(url,"blank")

    }
  }
}
</script>

<style scoped lang="less">
  /deep/ .van-col{
    padding: 8px 0px;
  }
  /deep/ .van-button{
    height: 38px;
    width: 100%;
  }
  .legend{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #F4664A;
    //background: #5B57EF;
    margin: 0px 5px 0px 15px;
  }
</style>
